'use strict';

angular.module('windmanagerApp')
  .directive('printTopPage', () => ({
    templateUrl: 'components/print/print-top-page.html',
    restrict: 'E',
    controller: 'PrintTopPageController',
    controllerAs: 'vm',
    scope: {
      overview: '='
    },
  }));
